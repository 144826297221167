<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>Plan</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          New Plan
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listPlan.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              No.
            </th>
            <th class="text-left">
              name
            </th>

            <th class="text-left">
              Price
            </th>
            <th class="text-left">
              Currency
            </th>
            <th class="text-left">
              Duration Type
            </th>
            <th class="text-left">
              Created_at
            </th>

            <th class="text-left">
              Updated_at
            </th>


          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listPlan" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>

            <td>
              {{ item.price }}
            </td>
            <td>
              {{ item.currency_short }}
            </td>
            <td>
              <div v-if="item.duration_type === 'yearly'">
                <span class="plan-yearly">yearly</span>
              </div>
              <div v-else>
                <span class="plan-monthly">monthly</span>
              </div>
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <vs-tooltip style="float: right" ref="addTooltip">
                    <v-btn
                        @click="promotionPlan(item.id)"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    >
                      <i class="fal fa-box-full" style="font-size: 18px;color:#878787;"></i>
                    </v-btn>
                    <template #tooltip>
                      Add Promotion
                    </template>
                  </vs-tooltip>

                </template>
              </v-menu>
            </td>
            <td class="text-start">


              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchPlan"
        >
        </Pagination>

        <ModalEdit fullscreen title="Edit Plan">
          <template v-slot="{ close }">
            <EditPlan :plan="plan" @close="close" @success="fetchPlan()"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeletePlan :plan_id="plan_id" @close="close" @success="fetchPlan()"/>
          </template>
        </ModalDelete>


        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import EditPlan from "../../../components/plan/EditPlan";
import DeletePlan from "../../../components/plan/DeletePlan";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    EditPlan,
    DeletePlan,
    defaultTableNoResult
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listPlan: [],
      plan_id: "",
      plan: {}

    }
  },
  methods: {
    promotionPlan(plan_id) {
      const {addTooltip} = this.$refs;
      if (addTooltip && addTooltip[0]) {
        addTooltip.forEach(function (item) {
          item.removeTooltip()
        })
      }
      setTimeout(() => {
        this.$router.push({
          name: "promotionPlan.index",
          params: {
            plan_id: plan_id,
          }
        }).catch(() => {

        })
      })
    },

    searchFilterItem() {
      this.fetchPlan();
    },

    filterPlan(plan_id) {
      return (
          this.listPlan.filter((item) => {
            return item.id == plan_id;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({name: 'plan.create'}).catch(() => {
      });
    },

    onEdit(plan_id) {
      this.plan = {
        ...this.filterPlan(plan_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(plan_id) {
      this.plan_id = plan_id
      this.$store.commit("modalDelete_State", true);
    },

    fetchPlan() {
      this.isLoading = true;
      this.$axios.get(`admin/plan`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listPlan = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listPlan.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchPlan();
  }
}
</script>

<style scoped lang="scss">
.plan-yearly {
  background-color: rgba(123, 237, 159, 0.3);
  color: rgba(106, 176, 76, 1.0);
  padding: 8px 22px;
  border-radius: 30px;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: $font-family;
}

.plan-monthly {
  background-color: rgba(249, 202, 36, 0.4);
  color: rgba(240, 147, 43, 0.9);
  padding: 8px 15px;
  border-radius: 30px;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: $font-family;

}
</style>
